import React from "react"
import globalStyles from '../../style/global.module.scss'
import { FormattedMessage } from 'gatsby-plugin-intl'


const PlauderButton = ({mode}) => {

    function getBtnClass(){
        return mode === "dark" ? globalStyles.colorbuttonDark : globalStyles.colorbuttonLight
    }

    return(
<button className = {getBtnClass()}>
<a href="mailto:office@sphericalelephant.com">
 <span><FormattedMessage id="Services.btn.chat"/></span></a>
 </button>
)
}

export default PlauderButton
