import * as React from "react"
import 'bootstrap/dist/css/bootstrap.min.css'
import GlobalStyles from '../../style/global.module.scss'
import Header from './header.js'
import Footer from './footer.js'
import Font from '../../style/font.css'
import CookieBanner from '../global/cookie.js'
import { Helmet } from "react-helmet"



const Layout = (props) => {

  function showFooter() {
    if (!props.hideFooter) {
      return <footer> <Footer /> </footer>
    }
  }

  return (

    <div className={GlobalStyles.wrapper}>
      <Helmet>
        <script key="window-env" type="text/javascript" src={'/env.js'} />
      </Helmet> 
      <CookieBanner hide={props.hide} />
      
      <link rel="stylesheet" href={Font}></link>
        <Header />
      <main>
        {props.children}
      </main>
      {showFooter()}
    </div>
  )
}

export default Layout
